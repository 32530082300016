module.exports = function (hljs) {
  var KEYWORDS = {
    keyword:
    // Normal keywords.
    'abstract as base bool break byte case catch char checked const continue decimal ' + 'default delegate do double enum event explicit extern finally fixed float ' + 'for foreach goto if implicit in int interface internal is lock long nameof ' + 'object operator out override params private protected public readonly ref sbyte ' + 'sealed short sizeof stackalloc static string struct switch this try typeof ' + 'uint ulong unchecked unsafe ushort using virtual void volatile while ' +
    // Contextual keywords.
    'add alias ascending async await by descending dynamic equals from get global group into join ' + 'let on orderby partial remove select set value var where yield',
    literal: 'null false true'
  };
  var NUMBERS = {
    className: 'number',
    variants: [{
      begin: '\\b(0b[01\']+)'
    }, {
      begin: '(-?)\\b([\\d\']+(\\.[\\d\']*)?|\\.[\\d\']+)(u|U|l|L|ul|UL|f|F|b|B)'
    }, {
      begin: '(-?)(\\b0[xX][a-fA-F0-9\']+|(\\b[\\d\']+(\\.[\\d\']*)?|\\.[\\d\']+)([eE][-+]?[\\d\']+)?)'
    }],
    relevance: 0
  };
  var VERBATIM_STRING = {
    className: 'string',
    begin: '@"',
    end: '"',
    contains: [{
      begin: '""'
    }]
  };
  var VERBATIM_STRING_NO_LF = hljs.inherit(VERBATIM_STRING, {
    illegal: /\n/
  });
  var SUBST = {
    className: 'subst',
    begin: '{',
    end: '}',
    keywords: KEYWORDS
  };
  var SUBST_NO_LF = hljs.inherit(SUBST, {
    illegal: /\n/
  });
  var INTERPOLATED_STRING = {
    className: 'string',
    begin: /\$"/,
    end: '"',
    illegal: /\n/,
    contains: [{
      begin: '{{'
    }, {
      begin: '}}'
    }, hljs.BACKSLASH_ESCAPE, SUBST_NO_LF]
  };
  var INTERPOLATED_VERBATIM_STRING = {
    className: 'string',
    begin: /\$@"/,
    end: '"',
    contains: [{
      begin: '{{'
    }, {
      begin: '}}'
    }, {
      begin: '""'
    }, SUBST]
  };
  var INTERPOLATED_VERBATIM_STRING_NO_LF = hljs.inherit(INTERPOLATED_VERBATIM_STRING, {
    illegal: /\n/,
    contains: [{
      begin: '{{'
    }, {
      begin: '}}'
    }, {
      begin: '""'
    }, SUBST_NO_LF]
  });
  SUBST.contains = [INTERPOLATED_VERBATIM_STRING, INTERPOLATED_STRING, VERBATIM_STRING, hljs.APOS_STRING_MODE, hljs.QUOTE_STRING_MODE, NUMBERS, hljs.C_BLOCK_COMMENT_MODE];
  SUBST_NO_LF.contains = [INTERPOLATED_VERBATIM_STRING_NO_LF, INTERPOLATED_STRING, VERBATIM_STRING_NO_LF, hljs.APOS_STRING_MODE, hljs.QUOTE_STRING_MODE, NUMBERS, hljs.inherit(hljs.C_BLOCK_COMMENT_MODE, {
    illegal: /\n/
  })];
  var STRING = {
    variants: [INTERPOLATED_VERBATIM_STRING, INTERPOLATED_STRING, VERBATIM_STRING, hljs.APOS_STRING_MODE, hljs.QUOTE_STRING_MODE]
  };
  var TYPE_IDENT_RE = hljs.IDENT_RE + '(<' + hljs.IDENT_RE + '(\\s*,\\s*' + hljs.IDENT_RE + ')*>)?(\\[\\])?';
  return {
    aliases: ['csharp', 'c#'],
    keywords: KEYWORDS,
    illegal: /::/,
    contains: [hljs.COMMENT('///', '$', {
      returnBegin: true,
      contains: [{
        className: 'doctag',
        variants: [{
          begin: '///',
          relevance: 0
        }, {
          begin: '<!--|-->'
        }, {
          begin: '</?',
          end: '>'
        }]
      }]
    }), hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE, {
      className: 'meta',
      begin: '#',
      end: '$',
      keywords: {
        'meta-keyword': 'if else elif endif define undef warning error line region endregion pragma checksum'
      }
    }, STRING, NUMBERS, {
      beginKeywords: 'class interface',
      end: /[{;=]/,
      illegal: /[^\s:,]/,
      contains: [hljs.TITLE_MODE, hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE]
    }, {
      beginKeywords: 'namespace',
      end: /[{;=]/,
      illegal: /[^\s:]/,
      contains: [hljs.inherit(hljs.TITLE_MODE, {
        begin: '[a-zA-Z](\\.?\\w)*'
      }), hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE]
    }, {
      // [Attributes("")]
      className: 'meta',
      begin: '^\\s*\\[',
      excludeBegin: true,
      end: '\\]',
      excludeEnd: true,
      contains: [{
        className: 'meta-string',
        begin: /"/,
        end: /"/
      }]
    }, {
      // Expression keywords prevent 'keyword Name(...)' from being
      // recognized as a function definition
      beginKeywords: 'new return throw await else',
      relevance: 0
    }, {
      className: 'function',
      begin: '(' + TYPE_IDENT_RE + '\\s+)+' + hljs.IDENT_RE + '\\s*\\(',
      returnBegin: true,
      end: /\s*[{;=]/,
      excludeEnd: true,
      keywords: KEYWORDS,
      contains: [{
        begin: hljs.IDENT_RE + '\\s*\\(',
        returnBegin: true,
        contains: [hljs.TITLE_MODE],
        relevance: 0
      }, {
        className: 'params',
        begin: /\(/,
        end: /\)/,
        excludeBegin: true,
        excludeEnd: true,
        keywords: KEYWORDS,
        relevance: 0,
        contains: [STRING, NUMBERS, hljs.C_BLOCK_COMMENT_MODE]
      }, hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE]
    }]
  };
};