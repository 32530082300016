module.exports = function (hljs) {
  return {
    aliases: ['md', 'mkdown', 'mkd'],
    contains: [
    // highlight headers
    {
      className: 'section',
      variants: [{
        begin: '^#{1,6}',
        end: '$'
      }, {
        begin: '^.+?\\n[=-]{2,}$'
      }]
    },
    // inline html
    {
      begin: '<',
      end: '>',
      subLanguage: 'xml',
      relevance: 0
    },
    // lists (indicators only)
    {
      className: 'bullet',
      begin: '^\\s*([*+-]|(\\d+\\.))\\s+'
    },
    // strong segments
    {
      className: 'strong',
      begin: '[*_]{2}.+?[*_]{2}'
    },
    // emphasis segments
    {
      className: 'emphasis',
      variants: [{
        begin: '\\*.+?\\*'
      }, {
        begin: '_.+?_',
        relevance: 0
      }]
    },
    // blockquotes
    {
      className: 'quote',
      begin: '^>\\s+',
      end: '$'
    },
    // code snippets
    {
      className: 'code',
      variants: [{
        begin: '^```\w*\s*$',
        end: '^```\s*$'
      }, {
        begin: '`.+?`'
      }, {
        begin: '^( {4}|\t)',
        end: '$',
        relevance: 0
      }]
    },
    // horizontal rules
    {
      begin: '^[-\\*]{3,}',
      end: '$'
    },
    // using links - title and link
    {
      begin: '\\[.+?\\][\\(\\[].*?[\\)\\]]',
      returnBegin: true,
      contains: [{
        className: 'string',
        begin: '\\[',
        end: '\\]',
        excludeBegin: true,
        returnEnd: true,
        relevance: 0
      }, {
        className: 'link',
        begin: '\\]\\(',
        end: '\\)',
        excludeBegin: true,
        excludeEnd: true
      }, {
        className: 'symbol',
        begin: '\\]\\[',
        end: '\\]',
        excludeBegin: true,
        excludeEnd: true
      }],
      relevance: 10
    }, {
      begin: /^\[[^\n]+\]:/,
      returnBegin: true,
      contains: [{
        className: 'symbol',
        begin: /\[/,
        end: /\]/,
        excludeBegin: true,
        excludeEnd: true
      }, {
        className: 'link',
        begin: /:\s*/,
        end: /$/,
        excludeBegin: true
      }]
    }]
  };
};