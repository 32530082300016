module.exports = function (hljs) {
  var START_BRACKET = '\\[';
  var END_BRACKET = '\\]';
  return {
    aliases: ['i7'],
    case_insensitive: true,
    keywords: {
      // Some keywords more or less unique to I7, for relevance.
      keyword:
      // kind:
      'thing room person man woman animal container ' + 'supporter backdrop door ' +
      // characteristic:
      'scenery open closed locked inside gender ' +
      // verb:
      'is are say understand ' +
      // misc keyword:
      'kind of rule'
    },
    contains: [{
      className: 'string',
      begin: '"',
      end: '"',
      relevance: 0,
      contains: [{
        className: 'subst',
        begin: START_BRACKET,
        end: END_BRACKET
      }]
    }, {
      className: 'section',
      begin: /^(Volume|Book|Part|Chapter|Section|Table)\b/,
      end: '$'
    }, {
      // Rule definition
      // This is here for relevance.
      begin: /^(Check|Carry out|Report|Instead of|To|Rule|When|Before|After)\b/,
      end: ':',
      contains: [{
        //Rule name
        begin: '\\(This',
        end: '\\)'
      }]
    }, {
      className: 'comment',
      begin: START_BRACKET,
      end: END_BRACKET,
      contains: ['self']
    }]
  };
};